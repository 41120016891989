import React, { Component } from 'react';
import {Cookies} from 'react-cookie';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.css';

import 'css/jqueryui_1.12.1.css'

// for IE11 support
import "isomorphic-fetch"
import { polyfill } from 'es6-promise';
polyfill();

const cookies = new Cookies();

const Layout = React.lazy(() => import('./containers/Layout'));
const SPALayout = React.lazy(() => import('./containers/SPALayout'));

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('pingpongBizToken')
      ? <Component {...props} />
      : <Redirect to='/login' />
    )} />
  )

const SPARoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('pingpongBizSpaToken')
      ? <Component {...props} />
      : <Redirect to='/SPA/401' />
    )} />
  )

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const PasswordRecovery = React.lazy(() => import('./views/Pages/PasswordRecovery'));
const RegistrationStart = React.lazy(() => import('./views/Pages/Registration/Preparation'));
const RegistrationSignup = React.lazy(() => import('./views/Pages/Registration/Signup'));
const RegistrationComplete = React.lazy(() => import('./views/Pages/Registration/Complete'));
const SamedayDeliveryApply = React.lazy(() => import('./views/Pages/SamedayDelivery/EarlyBirdApply'));
const SamedayDeliveryComplete = React.lazy(() => import('./views/Pages/SamedayDelivery/Complete'));

class App extends Component {
  constructor(props) {
    super(props)

    const pingpongBizSpaToken = cookies.get('pingpongBizSpaToken')
    if(pingpongBizSpaToken) {
      localStorage.setItem('pingpongBizSpaToken', pingpongBizSpaToken)
    }
    const pingpongWaybillConfig = cookies.get('pingpongWaybillConfig')
    if(pingpongWaybillConfig) {
      localStorage.setItem('pingpongWaybillConfig', JSON.stringify(pingpongWaybillConfig))
    }
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/password-recovery" name="Password Recovery Page" render={props => <PasswordRecovery {...props}/>} />
            <Route exact path="/registration/preparation" name="Registration Preparation" render={props => <RegistrationStart {...props}/>} />
            <Route exact path="/registration/signup" name="Registration Sign up" render={props => <RegistrationSignup {...props}/>} />
            <Route exact path="/registration/complete" name="Registration Complete" render={props => <RegistrationComplete {...props}/>} />
            <Route exact path="/sameday-delivery/earlybird" name="Apply Sameday Delivery EarlyBird" render={props => <SamedayDeliveryApply {...props}/>} />
            <Route exact path="/sameday-delivery/earlybird/complete" name="Sameday Delivery EarlyBird" render={props => <SamedayDeliveryComplete {...props}/>} />

            <Route exact path="/registration/:code/signup" name="Registration Sign up" render={props => <RegistrationSignup {...props}/>} />
            <Route exact path="/registration/:code/signup/:step/:showSamedayApply" name="Registration Sign up" render={props => <RegistrationSignup {...props}/>} />
            <Route exact path="/registration/:code/complete" name="Registration Complete" render={props => <RegistrationComplete {...props}/>} />

            <SPARoute path="/spa/" name="SPA" component={SPALayout} />

            {/*<Route exact path="/" name="Landing Page" render={props => <Landing {...props}/>} />*/}
            <PrivateRoute path="/" name="Home" component={Layout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
